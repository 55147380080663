
(function($){
    
    let $navigation = $('#navigation'),
        $nav_items_with_children = $navigation.find('.menu-item-has-children > a'),
        $menu_toggle = $('.menu_toggle');

    $nav_items_with_children.on('click', function(e){
        e.preventDefault();
        if( parseInt( $(window).width() ) <= 1024 ){
           $(this).parent().toggleClass('sub-menu-open'); 
        }
        
    });

    $menu_toggle.on('click', function(e){
        e.preventDefault();
        $('body').toggleClass('menu_open');
    });

    //Hero Scroll
    let $scroll_wrap = $('.hero_scroll_wrap'),
        $scroll_sections = $scroll_wrap.find('.section'),
        $scroll_section_count =  $scroll_sections.length,
        $scroll_width = parseInt($scroll_wrap.width() * --$scroll_section_count),
        $last_scroll = 0;

    if(
        $scroll_wrap.length && 
        parseInt( $(window).width() ) >= 1024 
    ){
        $scroll_wrap.css({ 'width' : parseInt($scroll_wrap.width() * $scroll_section_count) });
    }

    function scrollheader(){
        if( 
            $scroll_wrap.length && 
            parseInt( $(window).width() ) >= 1024 
        ){
            $('body').on('mousewheel',function(e){
                let $current_scroll  = $last_scroll + e.originalEvent.deltaY;
                if(
                    window.scrollY > 0 ||
                    $current_scroll <= 0 || 
                    $current_scroll >= $scroll_width
                ){
                    return;
                }
                e.preventDefault();
                $scroll_wrap.css( 'transform','translateX(' + $current_scroll *-1 + 'px)' );
                $last_scroll = $current_scroll;
                //console.log('scrolling', $last_scroll);
            });
        }
    }

    scrollheader();

    $(window).on('resize',function(){
        $scroll_width = parseInt($scroll_wrap.width() * --$scroll_section_count);
    })


    //Offerings Module
    let $offerings = $('.offering'),
        $offering_links = $('.offering_links').find('a');
    
    $offering_links.on('click',function(e){
        e.preventDefault();
        $offerings.removeClass('active');
        $offering_links.removeClass('active');
        $( $(this).attr('href') ).addClass('active');  
        $(this).addClass('active');  
    });

})(jQuery);

